import Head from 'next/head'
import { useSession } from "next-auth/react";
import Script from "next/script";

export const CustomHead = ({
  title="Taplio",
  desc="Get the social presence you deserve.",
  url="https://app.taplio.com",
  image="https://app.taplio.com/taplio_meta.jpg",
  type="website",
  noIndex=false,
}) => {

  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"

  return (
    <>
      <Head>
        <title>{title}</title>
        
        <link rel="canonical" href={url} />
        <meta name="description" content={desc}/>
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary" key="twcard" />
        <meta name="twitter:site" content={url} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />

        {
          (url?.includes("https://app.taplio.com") || noIndex) && (
            <meta name="robots" content="noindex" /> 
          )
        }

        {/* Open Graph */}
        <meta property="og:url" content={url} key="ogurl" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta property="og:image" content={image} key="ogimage" />
        <meta property="og:site_name" content={title} key="ogsitename" />
        <meta property="og:title" content={title} key="ogtitle" />
        <meta property="og:description" content={desc} key="ogdesc" />
        <meta property="og:type" content={type} />

        <Script id="facebook-pixel" strategy="afterInteractive">
          {`!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version="2.0";
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,"script",
              "https://connect.facebook.net/en_US/fbevents.js");
              fbq("init", "2991899637693143");
              fbq("track", "PageView");`}
        </Script>

        {/* <script dangerouslySetInnerHTML={{ __html: `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date(); (function(){ var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0]; s1.async=true; s1.src='https://embed.tawk.to/60c71b1c65b7290ac635d2d2/1f84s3ppn'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0); })();` }} /> */}
      </Head>
    </>
  );
};
